<template>
  <div class="container" id="impressum">
    <div class="row">
      <div class="col-12">
        <div class="logo-wrapper">
          <router-link to="/">
            <img
              id="logo"
              src="@/assets/logo/bureau-luthi-logo-main-small-black.png"
              alt=""
              width="240px"
            />
          </router-link>
        </div>
        <div class="row">
          <div class="col-12">
            <h2>Impressum</h2>
          </div>
          <div class="col-12 col-md-6">
            <p>Bureau Lüthi AG <br />St. Jakobstrasse 31 <br />8004 Zürich</p>
            <p>
              <span class="upper"> Telephone </span>
              <a href="tel:+41445526590">+41 44 552 65 90</a>
              <br />
              <span class="upper">Email </span>
              <a href="mailto:info@bureauluethi.com">info@bureauluethi.com</a>
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p id="adress"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
#impressum {
  padding-top: 4rem;
  min-height: 80vh;

  padding: 4rem 3rem 0px 3rem;

  #logo {
    margin-bottom: 80px;
  }

  h2 {
    margin-top: 10rem;
  }
}
</style>